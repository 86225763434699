exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accepted-papers-js": () => import("./../../../src/pages/accepted-papers.js" /* webpackChunkName: "component---src-pages-accepted-papers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invited-speakers-js": () => import("./../../../src/pages/invited-speakers.js" /* webpackChunkName: "component---src-pages-invited-speakers-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-submission-js": () => import("./../../../src/pages/submission.js" /* webpackChunkName: "component---src-pages-submission-js" */)
}

