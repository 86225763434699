import {
    Paper, Grid
} from '@mui/material'
import React from "react";

// React Component: ImportantDates
// Type: Component
// Description:
// 		This component is used to display the Contact information.

export default class Contact extends React.Component {
    render() {
        return (
            <Grid flexDirection="row"
                  justifyContent={'center'}
                  display='flex'
                  style={{width: "100%", padding: "45px", backgroundColor: '#f6fafd'}}
                  container>
                <Grid item justify="center" alignItems="center"
                      xs={12} sm={4} minWidth={316}>
                    <h1 style={{color: '#1e376d', textAlign: 'center'}}>
                        Contact Us
                    </h1>
                </Grid>

                <Grid item justify="center" alignItems="center"
                      xs={12} sm={8} sx={{paddingLeft: "20px", paddingRight: "20px"}}>
                    Please send enquiries to <a href="mailto:organizers@brainnn.us">organizers@brainnn.us</a>.
                </Grid>
            </Grid>
        )
    }
}

