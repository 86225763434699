import {
    Paper, Grid
} from '@mui/material'
import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// React Component: ImportantDates
// Type: Component
// Description:
// 		This component is used to display the important dates for the conference.

function createData(name, date) {
    return { name, date};
}

const rows = [
    createData('Paper Submission Deadline', "<del>October 1, 2022</del><p style='color: red'>October 15, 2022, 11:59pm AoE</p>"),
    createData('Notification of Acceptance', "November 8, 2022"),
    createData('Camera-ready deadline', "November 27, 2022"),
    createData('Workshop Date', "December 18, 2022"),
];

function createMarkup(text) {
    return {__html: text};
}

export default class ImportantDates extends React.Component {
    render() {
        return (
            <Grid flexDirection="row"
                  justifyContent={'center'}
                  display='flex'
                  style={{width: "100%", padding: "45px", backgroundColor: '#f6fafd'}}
                  container>
                <Grid item justify="center" alignItems="center"
                      xs={12} sm={4} minWidth={316}>
                    <h1 style={{color: '#1e376d', textAlign: 'center'}}>
                        Important Dates
                    </h1>
                </Grid>

                <Grid item justify="center" alignItems="center"
                      xs={12} sm={8} sx={{paddingLeft: "20px", paddingRight: "20px"}}>
                    <TableContainer component={Paper} sx={{ minWidth: 424, maxWidth: 794}}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{paddingLeft: "15px"}}>Event</TableCell>
                                    <TableCell>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={{paddingLeft: "15px"}}>
                                            {row.name}
                                        </TableCell>
                                        <TableCell dangerouslySetInnerHTML={createMarkup(row.date)}/>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        )
    }
}

